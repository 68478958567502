import vueFormGenerator from 'vue-form-generator';

export const model = {
	From: 0,
	To: 14,
	CountryId: 1,
	Objects: [],
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
		],
	},
	{
		styleClasses: 'col-xs-12 inline-input-group',
		fields: [
			{
				type: 'input',
				help: 'nejaky pekny help',
				inputType: 'number',
				model: 'From',
				min: 0,
				styleClasses: 'col-xs-12 first-input',
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'periodfrom',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'To',
				min: 0,
				styleClasses: 'col-xs-12 second-input',
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'to2691',
				},
			},
		],
	},
];
