export const tableColumns = [
	{
		model: 'reinvoicingDate',
		i18n: 'dateofreinvoici',
		sortable: true,
	},
	{
		model: 'PresentationEndDate',
		i18n: 'validto',
		sortable: true,
	},
	{
		model: 'ObjectId',
		i18n: 'oid',
		sortable: true,
	},
	{
		model: 'ObjectName',
		i18n: 'object',
		sortable: true,
	},
];
