<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="false"
		headerTitle="reinvoicingobje"
		tooltipMsg="manualforattrac3309"
	>
		<template #formActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('showobjectsforr')"
				@handleClick="showObjects()"
			></megau-button>

			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('generatereinvoi')"
				@handleClick="reinvoice()"
			></megau-button>
		</template>

		<template #afterForm>
			<reinvoicing-table ref="reinvoicingTable"
							   v-if="model.CountryId"
							   :countryId="model.CountryId"
							   :from="model.From"
							   :to="model.To"></reinvoicing-table>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import ReinvoicingTable from './reinvoicing.table';

import http from '../../services/helpers/http';

import { model, fields } from './reinvoicing.form-data';

export default {
	name: 'Reinvoicing',

	components: {
		DetailPage,
		ReinvoicingTable,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Reinvoicing',
			routeParam: false,
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		loadResources() {
			this.updateSchemaFields({
				CountryId: {
					values: this.$store.state.country.data,
				},
			});
		},

		showObjects() {
			this.$refs.reinvoicingTable.tableGetData();
		},
		async reinvoice() {
			this.startLoading(false);
			await http.post(this.controllerName + '/ReinvoiceAdmin', {
				from: this.model.From,
				to: this.model.To,
				countryId: this.model.CountryId,
			});
			this.stopLoading();
			this.$refs.reinvoicingTable.tableGetData();
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/components/detail-table.scss';
.vue-form-generator .inline-input-group {
	.form-group.error {
		position: relative;
		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}
	@include ig-first-item(23%, 79%, 22%);
	@include ig-second-item(10%, 10px);
}

#VueTables_th--reinvoicingDate {
	min-width: 200;
	width: 200px;
}
#VueTables_th--PresentationEndDate {
	min-width: 200;
	width: 200px;
}
#VueTables_th--ObjectId {
	min-width: 200;
	width: 200px;
}
#VueTables_th--ObjectName {
	min-width: 400;
	width: 400px;
}

.reinvoicingTable {
	.VueTables__columns-dropdown-wrapper, .VueTables__filters-row {
		display:none;
	}
	.card-body {
		padding:0;
	}

	&.card {
		border:0;
	}
}
</style>
