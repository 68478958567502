<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:ref="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:showActionsColumn="false"
		addNewRecordMsg
		:requestFunction="requestFunction"
		:defaultOrderBy="{}"
		class="reinvoicingTable"
	>
		<template v-slot:ObjectId="list">O{{ list.row.ObjectId }}</template>
		<template v-slot:reinvoicingDate="list">{{ list.row.reinvoicingDate | date }}</template>
		<template v-slot:PresentationEndDate="list">{{ list.row.PresentationEndDate | date }}</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { cloneDeep } from 'lodash';
import serviceCommon from '../../services/service/common.service';

import { tableColumns } from './reinvoicing.table-data';

export default {
	name: 'ReinvoicingTable',
	props: {
		countryId: { type: Number, required: true },
		from: { type: Number, required: true },
		to: { type: Number, required: true },
	},
	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ReinvoicingListItem',
			columns: tableColumns,
		};
	},

	methods: {
		requestFunction(data) {
			this.startLoading(false);
			let cdata = cloneDeep(data);

			if (!cdata.filter) {
				cdata.filter = {
					CountryId: this.countryId,
					From: this.from,
					To: this.to,
				};
			}

			const info = {
				data: this.transformRequestData(cdata),
				controller: this.controllerName,
				tableName: this.$options.name,
				onLoaded: () => {
					this.stopLoading();
				},
			};

			return serviceCommon.getListForTable(info);
		},
	},
};
</script>

<style lang="scss">
</style>
