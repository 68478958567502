var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    ref: _vm.$options.name,
    staticClass: "reinvoicingTable",
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      showActionsColumn: false,
      addNewRecordMsg: "",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: {},
    },
    scopedSlots: _vm._u([
      {
        key: "ObjectId",
        fn: function (list) {
          return [_vm._v("O" + _vm._s(list.row.ObjectId))]
        },
      },
      {
        key: "reinvoicingDate",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm._f("date")(list.row.reinvoicingDate)))]
        },
      },
      {
        key: "PresentationEndDate",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm._f("date")(list.row.PresentationEndDate)))]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }