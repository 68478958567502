var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: false,
          headerTitle: "reinvoicingobje",
          tooltipMsg: "manualforattrac3309",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "formActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("showobjectsforr"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.showObjects()
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("generatereinvoi"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.reinvoice()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "afterForm",
              fn: function () {
                return [
                  _vm.model.CountryId
                    ? _c("reinvoicing-table", {
                        ref: "reinvoicingTable",
                        attrs: {
                          countryId: _vm.model.CountryId,
                          from: _vm.model.From,
                          to: _vm.model.To,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          12354665
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }